var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{class:_vm.bgColour,attrs:{"id":"footer"}},[_c('div',{staticClass:"inner-content"},[_c('button',{staticClass:"refs-link",style:(_vm.referencesStyle),on:{"click":function($event){return _vm.$store.toggleModal('references')}}},[_vm._v(" References ")]),_c('div',{staticClass:"contact-us"},[_c('div',{staticClass:"social-media"},[_c('b',{staticClass:"bold-title small"},[_vm._v("Follow Us")]),_c('a',{attrs:{"href":"https://www.twitter.com/NovartisUSMed","target":"_blank"},on:{"click":function($event){return _vm.$gtag.event('click', {
              event_category: 'go_to_twitter',
              event_label: 'Twitter',
            })}}},[_c('img',{attrs:{"src":require("@/assets/img/sm-twitter.svg"),"alt":"Twitter"}})]),_c('a',{attrs:{"href":"https://www.linkedin.com/company/novartis-us-medical/","target":"_blank"},on:{"click":function($event){return _vm.$gtag.event('click', {
              event_category: 'go_to_linkedin',
              event_label: 'LinkedIn',
            })}}},[_c('img',{attrs:{"src":require("@/assets/img/sm-linkedin.svg"),"alt":"LinkedIn"}})])]),_c('div',{staticClass:"contact-us-content"},[_c('b',{staticClass:"bold-title"},[_vm._v("Contact Us")]),_c('div',{staticClass:"sub-items"},_vm._l((_vm.headerLinks),function(key){return _c('span',{key:key + '-menu'},[_c('a',{attrs:{"href":_vm.subItems[key].urlProd,"target":"_blank"},domProps:{"innerHTML":_vm._s(_vm.subItems[key].title)},on:{"click":function($event){return _vm.$gtag.event('click', {
                  event_category: _vm.subItems[key].eventCategory,
                  event_label: _vm.subItems[key].title,
                })}}}),_c('img',{attrs:{"src":require("@/assets/img/icon-browser-launch-black.svg"),"alt":"This link will open in a separate browser tab."}})])}),0),_c('p',[_vm._v(" You can reach us by Phone (1-888-669-6682), "),_c('a',{attrs:{"href":"https://medinfo.novartispharmaceuticals.com/EMedSubmitInquiry#email","target":"_blank"},on:{"click":function($event){return _vm.$gtag.event('click', {
                event_category: 'go_to_email',
                event_label: 'Email',
              })}}},[_vm._v("Email")]),_vm._v(", or "),_c('a',{attrs:{"href":"https://medinfo.novartispharmaceuticals.com/EMedChat","target":"_blank"},on:{"click":function($event){return _vm.$gtag.event('click', {
                event_category: 'go_to_live_chat',
                event_label: 'Live Chat',
              })}}},[_vm._v("Live Chat")])]),_vm._m(0)])]),_vm._m(1),_c('div',{staticClass:"sitemap"},[_c('a',{attrs:{"href":"/sitemap"},on:{"click":function($event){return _vm.$gtag.event('click', {
            event_category: 'launch_menu_item',
            event_label: 'Site Map',
          })}}},[_vm._v("Site Map")])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTerms),expression:"showTerms"}],staticClass:"copy-terms"},[_vm._v(" Use of website is governed by the "),_c('a',{attrs:{"href":"https://www.novartis.us/terms-use","target":"_blank","aria-describedby":"new-window-6"},on:{"click":function($event){return _vm.$gtag.event('click', {
            event_category: 'go_to_terms',
            event_label: 'Terms of Use',
          })}}},[_vm._v("Terms of Use")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://www.novartis.us/privacy-policy","target":"_blank","aria-describedby":"new-window-6"},on:{"click":function($event){return _vm.$gtag.event('click', {
            event_category: 'go_to_privacy',
            event_label: 'Privacy Policy',
          })}}},[_vm._v("Privacy Policy")]),_vm._v(". ")]),_c('div',{staticClass:"legal-copy"},[_c('div',{staticClass:"copyright"},[_c('span',[_vm._v("© "+_vm._s(new Date().getFullYear())+" Novartis Pharmaceuticals Corporation.")]),_c('span',{staticClass:"linebreak"},[_vm._v(" All rights reserved.")])]),_c('div',{staticClass:"date-job-code"},[_c('span',{staticClass:"date"},[_vm._v("1/25")]),_c('span',{staticClass:"jobcode",domProps:{"innerHTML":_vm._s(_vm.jobCode)}})])]),_vm._m(2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Monday-Friday, 8:30 "),_c('span',{staticClass:"small-caps-font-variant"},[_vm._v("AM")]),_vm._v("-5:00 "),_c('span',{staticClass:"small-caps-font-variant"},[_vm._v("PM")]),_vm._v(" ET")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"location"},[_c('b',{staticClass:"bold-title"},[_vm._v("Novartis Pharmaceuticals Corporation")]),_c('p',[_vm._v("One Health Plaza")]),_c('p',[_vm._v("East Hanover, NJ 07936-1080")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"hidden":""}},[_c('span',{attrs:{"id":"new-window-6"}},[_vm._v("Select to launch an external site in a new tab.")])])
}]

export { render, staticRenderFns }