import { videos } from "../assets/covid-videos";
import { execSync } from "child_process";

const gitLastModTime = (filePath) => {
  filePath = filePath.replace("../", "./src/");
  try {
    const gitLog = execSync(`git log -1 --format=%at -- "${filePath}"`, {
      encoding: "utf-8",
    });
    const timestamp = parseInt(gitLog, 10);
    return new Date(timestamp * 1000).toISOString().replace(/T.*/g, "");
  } catch (error) {
    console.error(
      `Error getting last modified time for ${filePath}: ${error.message}`
    );
    return null;
  }
};

export const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
    meta: {
      sitemap: {
        priority:   1,
        lastmod: gitLastModTime("../views/Home.vue"),
      },
    },
  },
  {
    path: "/diagnosis",
    name: "diagnosis",
    component: () =>
      import(
        /* webpackChunkName: "HomeRecognizing" */ "../views/HomeRecognizing.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/HomeRecognizing.vue"),
      },
    },
  },
  {
    path: "/mechanism-of-disease",
    name: "mechanism-of-disease",
    component: () =>
      import(
        /* webpackChunkName: "HomeNeuropathology" */ "../views/HomeNeuropathology.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/HomeNeuropathology.vue"),
      },
    },
  },
  {
    path: "/mechanism-of-disease/video",
    name: "ms-mod",
    component: () =>
      import(/* webpackChunkName: "MSModVideo" */ "../views/MSModVideo.vue"),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/MSModVideo.vue"),
      },
    },
  },
  {
    path: "/mechanism-of-disease/b-t-cell-pathophysiology/video",
    name: "ms-patho-video",
    component: () =>
      import(
        /* webpackChunkName: "MSPathoVideo" */ "../views/MSPathoVideo.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/MSPathoVideo.vue"),
      },
    },
  },
  {
    path: "/sitemap",
    name: "sitemap",
    component: () =>
      import(/* webpackChunkName: "Sitemap" */ "../views/Sitemap.vue"),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/Sitemap.vue"),
      },
    },
  },
  {
    path: "/management",
    name: "hcppatientconnections",
    component: () =>
      import(
        /* webpackChunkName: "HomePatientConnection" */ "../views/HomePatientConnection.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/HomePatientConnection.vue"),
      },
    },
  },
  {
    path: "/progression",
    name: "exploring",
    component: () =>
      import(
        /* webpackChunkName: "HomeDiseaseProgression" */ "../views/HomeDiseaseProgression.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/HomeDiseaseProgression.vue"),
      },
    },
  },
  {
    path: "/patient-centered-care",
    name: "patient-centered-care",
    component: () =>
      import(/* webpackChunkName: "HomeCare" */ "../views/HomeCare.vue"),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/HomeCare.vue"),
      },
    },
  },

  {
    path: "/additional-resources",
    name: "additional-resources",
    component: () =>
      import(/* webpackChunkName: "Resources" */ "../views/Resources.vue"),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/Resources.vue"),
      },
    },
  },
  {
    path: "/covid-resources",
    name: "covid-resources",
    component: () =>
      import(
        /* webpackChunkName: "ResourcesCovid" */ "../views/ResourcesCovid.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/ResourcesCovid.vue"),
      },
    },
  },
  {
    path: "/glossary",
    name: "glossary",
    component: () =>
      import(/* webpackChunkName: "Glossary" */ "../views/Glossary.vue"),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/Glossary.vue"),
      },
    },
  },
  {
    path: "/ms-and-covid-19/telemedicine/video",
    name: "telemedicine",
    component: () =>
      import(
        /* webpackChunkName: "Telemedicine" */ "../views/Telemedicine.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/Telemedicine.vue"),
      },
    },
  },
  {
    path: "/ms-and-covid-19/:id/video",
    name: "covid-video",
    slug: "covid-video",
    component: () =>
      import(/* webpackChunkName: "CovidVideo" */ "../views/CovidVideo.vue"),
    meta: {
      sitemap: {
        slugs: Object.keys(videos),
        lastmod: gitLastModTime("../views/CovidVideo.vue"),
      },
    },
  },
  {
    path: "/progression/cognitive-functioning/video",
    name: "cognitive-func-video",
    component: () =>
      import(
        /* webpackChunkName: "CognitiveFuncVideo" */ "../views/CognitiveDeclineVideo.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/CognitiveDeclineVideo.vue"),
      },
    },
  },
  {
    path: "/patient-centered-care/empowering-patients/video",
    name: "empower-patient-video",
    component: () =>
      import(
        /* webpackChunkName: "EmpowerPatientVideo" */ "../views/EmpowerPatientVideo.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/EmpowerPatientVideo.vue"),
      },
    },
  },
  {
    path: "/patient-centered-care/diversity-clinical-trials/video",
    name: "many-faces-video",
    component: () =>
      import(
        /* webpackChunkName: "ManyFacesVideo" */ "../views/ManyFacesVideo.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/ManyFacesVideo.vue"),
      },
    },
  },
  {
    path: "/mechanism-of-disease/immunoglobulins-and-ms/video",
    name: "immunoglobulins",
    component: () =>
      import(
        /* webpackChunkName: "ImmunoglobulinsVideo" */ "../views/ImmunoglobulinsVideo.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/ImmunoglobulinsVideo.vue"),
      },
    },
  },
  {
    path: "/diagnosis/diagnostic-challenges/video",
    name: "diagnostic-challenges-video",
    component: () =>
      import(
        /* webpackChunkName: "DiagnosticChallengesVideo" */ "../views/DiagnosticChallengesVideo.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/DiagnosticChallengesVideo.vue"),
      },
    },
  },
  {
    path: "/mechanism-of-disease/role-of-immunoglobulins/pdf",
    name: "immunoglobulin",
    component: () =>
      import(
        /* webpackChunkName: "Immunoglobulin" */ "../views/Immunoglobulin.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/Immunoglobulin.vue"),
      },
    },
  },

  {
    path: "/diagnosis/diagnostic-criteria",
    name: "diagnostic-criteria",
    component: () =>
      import(
        /* webpackChunkName: "DiagnosingMs" */ "../views/DiagnosingMs.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/DiagnosingMs.vue"),
      },
    },
  },

  {
    path: "/diagnosis/diagnostic-challenges/pdf",
    name: "diagnostic-challenges",
    component: () =>
      import(
        /* webpackChunkName: "DiagnosticChallenges" */ "../views/DiagnosticChallenges.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/DiagnosticChallenges.vue"),
      },
    },
  },
  {
    path: "/diagnosis/subtle-symptoms-and-disease-progression/pdf",
    name: "subtle-symptoms-and-disease-progression",
    component: () =>
      import(
        /* webpackChunkName: "SubtleSymptomsAndDiseaseProgression" */ "../views/SubtleSymptomsAndDiseaseProgression.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/SubtleSymptomsAndDiseaseProgression.vue"),
      },
    },
  },
  {
    path: "/mechanism-of-disease/symptoms-and-pathogenic-immune-system/pdf",
    name: "pathogenic-b-t-cells",
    component: () =>
      import(
        /* webpackChunkName: "PathogenicBTCells" */ "../views/PathogenicBTCells.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/PathogenicBTCells.vue"),
      },
    },
  },
  {
    path: "/mechanism-of-disease/ms-early-pathophysiology/pdf",
    name: "early-pathophysiology",
    component: () =>
      import(
        /* webpackChunkName: "EarlyPathophysiology" */ "../views/EarlyPathophysiology.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/EarlyPathophysiology.vue"),
      },
    },
  },
  {
    path: "/mechanism-of-disease/neuroinflammation-neurodegeneration/pdf",
    name: "neuroinflammation",
    component: () =>
      import(
        /* webpackChunkName: "NeuroinflammationAndNeurodegeneration" */ "../views/NeuroinflammationAndNeurodegeneration.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/NeuroinflammationAndNeurodegeneration.vue"),
      },
    },
  },
  {
    path: "/management/disease-modifying-treatments/pdf",
    name: "treatment-strategies",
    component: () =>
      import(
        /* webpackChunkName: "TreatmentStrategies" */ "../views/TreatmentStrategies.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/TreatmentStrategies.vue"),
      },
    },
  },
  {
    path: "/management/timing-of-treatment-initiation/pdf",
    name: "early-treatment",
    component: () =>
      import(
        /* webpackChunkName: "EarlyTreatment" */ "../views/EarlyTreatment.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/EarlyTreatment.vue"),
      },
    },
  },
  {
    path: "/management/evolving-therapeutic-targets/pdf",
    name: "current-therapeutic-targets",
    component: () =>
      import(
        /* webpackChunkName: "TherapeuticTargets" */ "../views/TherapeuticTargets.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/TherapeuticTargets.vue"),
      },
    },
  },
  {
    path: "/management/family-planning/pdf",
    name: "family-planning",
    component: () =>
      import(
        /* webpackChunkName: "FamilyPlanning" */ "../views/FamilyPlanning.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/FamilyPlanning.vue"),
      },
    },
  },
  {
    path: "/management/pregnancy-management/pdf",
    name: "pregnancy-management",
    component: () =>
      // import(/* webpackChunkName: "NotFound" */ "../views/NotFound.vue"),
      import(
        /* webpackChunkName: "PregnancyManagement" */ "../views/PregnancyManagement.vue"
      ),
    meta: {
      sitemap: {
        // lastmod: gitLastModTime("../views/NotFound.vue"),
        lastmod: gitLastModTime("../views/PregnancyManagement.vue"),
      },
    },
  },
  {
    path: "/progression/cognitive-impairment/pdf",
    name: "cognitive-impairment",
    component: () =>
      import(
        /* webpackChunkName: "CognitiveFunctioning" */ "../views/CognitiveFunctioning.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/CognitiveFunctioning.vue"),
      },
    },
  },
  {
    path: "/progression/patient-views-on-disease-progression/pdf",
    name: "patient-views-on-disease-progression",
    component: () =>
      import(
        /* webpackChunkName: "PatientPerspectivesOnProgression" */ "../views/PatientPerspectivesOnProgression.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/PatientPerspectivesOnProgression.vue"),
      },
    },
  },
  {
    path: "/patient-centered-care/holistic-treatment/pdf",
    name: "holistic-treatment",
    component: () =>
      import(
        /* webpackChunkName: "HolisticPatientCare" */ "../views/HolisticPatientCare.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/HolisticPatientCare.vue"),
      },
    },
  },
  {
    path: "/patient-centered-care/empowering-patients/pdf",
    name: "empowering-patients",
    component: () =>
      import(
        /* webpackChunkName: "EmpoweringPatients" */ "../views/EmpoweringPatients.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/EmpoweringPatients.vue"),
      },
    },
  },
  {
    path: "/management/anti-cd20-monoclonal-antibodies/video",
    name: "anti-cd20",
    component: () =>
      import(
        /* webpackChunkName: "AntiCd20Video" */ "../views/AntiCd20Video.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/AntiCd20Video.vue"),
      },
    },
  },
  {
    path: "/mechanism-of-disease/neuroinflammation-neurodegeneration/video",
    name: "ms-video",
    component: () =>
      import(/* webpackChunkName: "MSVideo" */ "../views/MSVideo.vue"),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/MSVideo.vue"),
      },
    },
  },
  {
    path: "/mechanism-of-disease/neuroimmunology-considerations-in-ms/video",
    name: "neuroimmunology-considerations",
    component: () =>
      import(
        /* webpackChunkName: "NeuroimmunologyConsiderations" */ "../views/NeuroimmunologyConsiderations.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/NeuroimmunologyConsiderations.vue"),
      },
    },
  },
  {
    path: "/resources",
    name: "resources-home",
    component: () =>
      import(
        /* webpackChunkName: "ResourcesHome" */ "../views/ResourcesHome.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/ResourcesHome.vue"),
      },
    },
  },
  {
    path: "/search-results",
    name: "searchresults",
    component: () =>
      import(
        /* webpackChunkName: "SearchResults" */ "../views/SearchResults.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/SearchResults.vue"),
      },
    },
  },

  // PODCASTS
  {
    path: "/neurologists-perspective/podcast",
    name: "neurologists-perspective",
    component: () =>
      import(
        /* webpackChunkName: "NeurologistsPerspectivePodcast" */ "../views/NeurologistsPerspectivePodcast.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/NeurologistsPerspectivePodcast.vue"),
      },
    },
  },
  {
    path: "/disease-continuum/podcast",
    name: "disease-continuum-podcast",
    component: () =>
      import(
        /* webpackChunkName: "DiseaseContinuumPodcast" */ "../views/DiseaseContinuumPodcast.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/DiseaseContinuumPodcast.vue"),
      },
    },
  },
  {
    path: "/pregnancy-management/podcast",
    name: "pregnancy-management-podcast",
    component: () =>
      import(
        /* webpackChunkName: "PregnancyManagementPodcast" */ "../views/PregnancyManagementPodcast.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/PregnancyManagementPodcast.vue"),
      },
    },
  },
  {
    path: "/patient-adherence/podcast",
    name: "patient-adherence-podcast",
    component: () =>
      import(
        /* webpackChunkName: "PatientAdherencePodcast" */ "../views/PatientAdherencePodcast.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/PatientAdherencePodcast.vue"),
      },
    },
  },
  {
    path: "/switching-dmts/podcast",
    name: "switching-dmts-podcast",
    component: () =>
      import(
        /* webpackChunkName: "SwitchingDmtsPodcast" */ "../views/SwitchingDmtsPodcast.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/SwitchingDmtsPodcast.vue"),
      },
    },
  },
  {
    path: "/maximizing-adherence/podcast",
    name: "maximizing-adherence",
    component: () =>
      import(
        /* webpackChunkName: "MaximizingAdherencePodcast" */ "../views/MaximizingAdherencePodcast.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/MaximizingAdherencePodcast.vue"),
      },
    },
  },
  {
    path: "/managing-ms/podcast",
    name: "managing-ms",
    component: () =>
      import(
        /* webpackChunkName: "ManagingMsPodcast" */ "../views/ManagingMsPodcast.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/ManagingMsPodcast.vue"),
      },
    },
  },
  {
    path: "/silent-progression/podcast",
    name: "silent-progression",
    component: () =>
      import(
        /* webpackChunkName: "SilentProgressionPodcast" */ "../views/SilentProgressionPodcast.vue"
      ),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/SilentProgressionPodcast.vue"),
      },
    },
  },

  // If path not found, redirect back home
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../views/NotFound.vue"),
    meta: {
      sitemap: {
        lastmod: gitLastModTime("../views/NotFound.vue"),
      },
    },
  },
];
