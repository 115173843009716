<template>
  <div id="app" :style="isIE ? appStyle : ''" tabindex="-1">
    <transition name="fade">
      <WelcomeDisclaimer
        v-if="
          cookies.get('isHcp') === 'false' || cookies.isKey('isHcp') === false
        "
      />
    </transition>
    <transition name="fade">
      <CookieDisclaimer
        v-if="
          cookies.get('acceptCookies') === 'false' ||
          cookies.isKey('acceptCookies') === false
        "
      />
    </transition>

    <LegalDisclaimer />

    <AppHeader
      :showMenuButton="$router.currentRoute.name !== 'welcome'"
      :showTerms="true"
    />

    <div style="position: relative">
      <router-view class="page" />
      <div class="overlay" v-if="overlay">
        <OrientationWarning @closeTheOverLay="setTheValuesOnDismiss" />
      </div>
    </div>

    <AppFooter
      jobCode="388555-2"
      :showTerms="true"
      :showReferences="$store.state.showRefs"
    />

    <transition name="fade">
      <BtnTop
        v-show="showTopButton && $router.currentRoute.name !== 'welcome'"
      />
    </transition>

    <span
      style="font-style: italic; visibility: hidden; position: absolute"
      aria-hidden="true"
      >Novartis</span
    >
  </div>
</template>

<script>
import IE11RouterFix from "@/mixins/IE11RouterFix"
import WelcomeDisclaimer from "@/components/novartis/WelcomeDisclaimer.vue"
import OrientationWarning from "./components/novartis/OrientationWarning.vue"
export default {
  name: "App",
  mixins: [IE11RouterFix],
  components: {
    WelcomeDisclaimer,
    OrientationWarning,
  },
  data() {
    return {
      scrollTop: 0,
      prevScroll: 0,
      state: this.$store.state,
      cookies: this.$cookies,
      width: window.innerWidth,
      scrollTicks: 0,
      currentSelectedNavTab: "",
      mediaSize: "",
      orientation: "",
      overlay: false,
    }
  },
  computed: {
    appStyle() {
      const styleObj = {}
      if (this.$route.name === "welcome") {
        /*
          IE11 doesn't recognize min-height of flex container.
          Explicitly set height on welcome page so that
          content fills up the rest of the page and
          the footer appears to anchor at the bottom.
        */
        styleObj.height = "100%"
      }
      return styleObj
    },
    isIE() {
      let ua = window.navigator.userAgent
      let msie = ua.indexOf("MSIE ")

      if (msie > 0 || navigator.appVersion.indexOf("Trident/") > -1) {
        // IE11 check
        return true
      } else if (document.documentMode || /Edge/.test(navigator.userAgent)) {
        // Edge check
        return true
      }

      // Return false for all other browsers
      return false
    },

    showTopButton() {
      // return this.scrollTop > 0;
      if (this.prevScroll > this.scrollTop) {
        return this.scrollTop > window.innerHeight / 2
      } else {
        return false
      }
    },
  },
  watch: {
    $route() {
      document.getElementById("app").focus()
    },
    "$store.showModal": function (modalId) {
      if (modalId !== "") {
        document.body.classList.add("no-scroll")
      } else {
        if (document.body.classList.contains("no-scroll"))
          document.body.classList.remove("no-scroll")
      }
    },
  },
  provide() {
    return {
      // goToOverview: this.goToOverview,
      gotoRoute: this.gotoRoute,
      highlightTabSelected: this.highlightTabSelected,
    }
  },
  methods: {
    updateScrollTop() {
      // Add fallback as scrollTop property varies by browser.
      if (this.isIE) {
        this.scrollTicks++
        if (this.scrollTicks % 3 === 0) {
          this.gainScrollValues()
        }
      } else {
        this.gainScrollValues()
      }
    },
    gainScrollValues() {
      this.prevScroll = this.scrollTop
      this.scrollTop = window.scrollY || window.pageYOffset
    },
    scrollHandler() {
      this.updateScrollTop()
    },

    highlightTabSelected(selectedTab) {
      if (this.currentSelectedTab !== selectedTab) {
        if (this.currentSelectedTab) {
          const previousTab = document.getElementById(this.currentSelectedTab)
          previousTab.classList.remove("selected")
        }
        const currentTab = document.getElementById(selectedTab)
        currentTab.classList.add("selected")
      }
    },

    setTheOrientation() {
      if (window.innerWidth > window.innerHeight) {
        this.orientation = "landscape"
      } else {
        this.orientation = "portrait"
      }
    },

    setTheValuesOnDismiss() {
      this.$cookies.set("overlayShown", true)
      window.sessionStorage.setItem("overlayShown", true)
      this.overlay = false
      window.removeEventListener("resize", this.myEventHandler)
    },

    myEventHandler() {
      this.setTheOrientation()
      this.detectMediaSize()

      if (
        this.mediaSize !== "desktop" &&
        this.orientation === "portrait" &&
        (window.sessionStorage.getItem("overlayShown") === null ||
          window.sessionStorage.getItem("overlayShown") === undefined)
      ) {
        this.overlay = true
      } else {
        this.overlay = false
      }
    },

    detectMediaSize() {
      if (window.matchMedia("(max-width: 750px)").matches) {
        this.mediaSize = "mobile"
      } else if (
        window.matchMedia("(min-width: 750px) and (max-width: 1199px)").matches
      ) {
        this.mediaSize = "tablet"
        this.setTheOrientation()
      } else {
        this.mediaSize = "desktop"
      }
    },
  },

  mounted() {
    this.myEventHandler()
    window.addEventListener("resize", this.myEventHandler)
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.myEventHandler)
  },
  // mounted() {
  //   let body = document.body;

  //   // Reset scroll position on first launch

  //   if (!window.location.hash.includes("?card=")) {
  //     body.scrollTop = 0;
  //     document.documentElement.scrollTop = 0;
  //   } else {
  //     let cardScrollYTarget = window.location.hash.split("#/?card=")[1];
  //     let cardScrollY =
  //       window.pageYOffset +
  //       document.getElementById(cardScrollYTarget).getBoundingClientRect().top;
  //     body.scrollTop = cardScrollY;
  //   }

  //   window.addEventListener("scroll", this.scrollHandler);
  // },
  // beforeDestroy() {
  //   window.removeEventListener("scroll", this.scrollHandler);
  // },
}
</script>

<style lang="scss">
@import "@/scss/styles";

// Custom Plyr Styles (for AudioPlayer.vue)
:root {
  --plyr-audio-control-background-hover: #0460a9;
  --plyr-audio-control-color: #fff;
  --plyr-audio-control-color-hover: none;
  --plyr-audio-controls-background: #0460a9;
  --plyr-color-main: #fff;
  --plyr-control-radius: 0px;
  --plyr-control-spacing: 8px;
}

body {
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  &.no-scroll {
    overflow: hidden;
  }
}
.VueCarousel-navigation-button {
  top: 45% !important;
}
.VueCarousel-navigation-prev {
  &:after {
    content: url("assets/img/icon-chevron-black.svg");
    position: absolute;
    transform: rotate(-180deg) translate(13px, 20px);
  }
}
.VueCarousel-navigation-next {
  &:after {
    content: url("assets/img/icon-chevron-black.svg");
    position: relative;
  }
}
#app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  font-family: "Volta Modern Text Web", Avenir, Helvetica, Arial, sans-serif;
  font-size: rem(16px);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $black;

  &:focus,
  &:active {
    outline: none;
  }

  &.no-scroll {
    overflow: hidden;
  }
}

.page {
  flex-grow: 1;
}

#cookie-disclaimer,
#footer {
  flex-shrink: 0;
}

.inner-wrapper {
  width: 100%;
  display: flex;
  flex-basis: auto;
  justify-content: center;
}

.inner-content {
  height: 100%;
  width: 100%;
  // margin: 0 auto;
  max-width: 1024px;
  text-align: left;
  padding: rem(25px);
  z-index: 1;

  // @media (min-width: 1680px) {
  //   max-width: 1400px !important;
  // }
}

.flex-container {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.bottom-border {
  padding-bottom: rem(20px);
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 90px;
    background: $white;
  }
}

.btn-cta {
  border: 2px solid $white;
  color: $white;
  background: none;
  padding: rem(20px);
  width: rem(350px);
  display: block;
}

.btn-back {
  border: 1px solid $blue-dark;
  color: $blue-dark;
  background: none;
  padding: rem(20px);
  display: block;
}

.launch-text {
  margin-top: rem(10px);
  font-size: rem(12px);
  img {
    width: 11px;
    margin-right: 5px;
  }
}

.acr {
  margin-top: rem(10px);
  font-size: rem(12px);
}
a {
  color: #221f1f;
}
button,
a,
input,
label,
select,
option,
video,
video::-webkit-media-controls-timeline,
video::-webkit-media-controls-panel,
video::-webkit-media-controls-play-button,
video::-webkit-media-controls-fullscreen-button,
video::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-mute-button {
  &:focus {
    outline: 4px solid #ffd086;
  }
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 4px solid #ffd086;
}

video:-moz-focusring {
  outline: 4px solid #ffd086;
}

a:-moz-focusring {
  outline: 4px solid #ffd086;
}

// Transitions
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
