<template>
  <div id="glossary">
    <header>
      <InnerContent>
        <ButtonReturn colour="black" />
        <h1 class="title">Glossary of Terms</h1>
      </InnerContent>
    </header>
    <InnerContent>
      <ul>
        <li>
          <strong>Antibody<sup>1</sup></strong
          >: A protein produced by the body’s immune system when it detects
          foreign substances, called antigens. Each type of antibody is unique
          and defends the body against one specific type of antigen. Antibodies
          may also be produced when the immune system mistakenly targets healthy
          tissue, which happens with an autoimmune disorder.
        </li>
        <li>
          <strong>Antigen<sup>1</sup></strong
          >: A foreign molecule or substance that stimulates the immune system
          to activate a series of events to eliminate the antigen. In multiple
          sclerosis (MS), the immune system misdirects the reactivity to
          antigens and instead targets healthy tissue in the central nervous
          system (CNS), which is referred to as autoimmunity. Examples of
          antigens include microorganisms (such as bacteria, fungi, parasites,
          and viruses) and chemicals.
        </li>
        <li>
          <strong>Antigen-presenting cell (APC)<sup>2</sup></strong
          >: Derived from bone marrow precursors, these cells present antigens
          to lymphocytes—mostly T cells. In MS, autoreactive effector CD4 T
          cells are locally reactivated by APCs and recruit additional T cells
          and macrophages to establish inflammatory lesions.
        </li>
        <li>
          <strong>Astrocytes<sup>2</sup></strong
          >: Star-shaped neuronal cells in the brain and spinal cord involved in
          multiple aspects of CNS function, including synapse maintenance,
          neurotransmission, phagocytosis, and blood-brain barrier (BBB)
          formation.
        </li>
        <li>
          <strong>Autoimmune disease<sup>1</sup></strong
          >: When the body’s own immune system attacks its own tissue, it is
          known as an autoimmune disease. MS is considered an autoimmune
          disease.
        </li>
        <li>
          <strong>Autoreactive cells<sup>3</sup></strong
          >: Cells that act against their own cells or tissues. In MS, B cells
          produce CNS-autoreactive antibodies.
        </li>
        <li>
          <strong>Axon<sup>1</sup></strong
          >: The wire-like nerve fibers that conduct signals between neurons
          (nerves of the brain and spinal cord). Axons have a protective myelin
          covering and are found in the white matter of the brain.
        </li>
        <li>
          <strong>Biomarker<sup>2</sup></strong
          >: A biological marker that correlates with disease activity. The most
          commonly used biomarker in MS is magnetic resonance imaging (MRI).
        </li>
        <li>
          <strong>Blood-brain barrier (BBB)<sup>1</sup></strong
          >: A protective barrier that lines the blood vessels. This layer of
          cells is designed to prevent damaging cells and other substances in
          the blood from entering the brain, optic nerves, and spinal cord of
          the CNS.
        </li>
        <li>
          <strong>Central nervous system (CNS)<sup>1</sup></strong
          >: The system comprising the brain, optic nerves, and spinal cord that
          functions to send and receive nerve impulses throughout the body.
        </li>
        <li>
          <strong>Cerebrospinal fluid (CSF)<sup>1,4</sup></strong
          >: A clear, colorless liquid that surrounds the brain and spinal cord
          to protect the CNS and assist in the circulation of nutrients and
          removal of waste products.
        </li>
        <li>
          <strong>Clinical attack (flare)<sup>5</sup></strong
          >: A monophasic clinical episode with patient-reported symptoms and
          objective findings reflecting a focal or multifocal inflammatory
          demyelinating event in the CNS, developing acutely or subacutely, with
          a duration of at least 24 hours, with or without recovery, and in the
          absence of fever or infection.
        </li>
        <li>
          <strong>Clinically isolated syndrome (CIS)<sup>1</sup></strong
          >: Prior to a diagnosis of MS, CIS involves a single attack (or the
          appearance of one or more symptoms characteristic of MS) in a patient
          with a very high risk of developing MS, when no other diseases or
          causes for symptoms are apparent.
        </li>
        <li>
          <strong>Cognitive fog (“cog fog”)<sup>4</sup></strong
          >: Refers to cognitive changes, including inhibited ability to think,
          reason, concentrate, or remember.
        </li>
        <li>
          <strong>Cognitive impairment<sup>1</sup></strong
          >: Impairment of cognitive function. In MS, typical impairments
          include information-processing speed and ability, cognitive
          flexibility, problems with storage, manipulation, and retrieval of
          information, and executive function (planning, working memory,
          attention, and problem solving).
        </li>
        <li>
          <strong>Cytokines<sup>1</sup></strong
          >: Small proteins that may stimulate or inhibit the function of other
          cells and the inflammation process. These proteins connect to specific
          receptors found on the surface of cells and send messages from one
          cell to another.
        </li>
        <li>
          <strong>Demyelination<sup>1</sup></strong
          >: Damage to the protective (insulating) covering of the nerves
          (myelin) of the CNS that causes interruptions in the flow of nerve
          impulses in the CNS. This can ultimately affect a wide range of
          functions.
        </li>
        <li>
          <strong>Disease-modifying treatment (DMT)<sup>4</sup></strong
          >: Drugs that may reduce relapses, delay progression of disability,
          and/or limit new CNS inflammation in people with MS.
        </li>
        <li>
          <strong>Expanded Disability Status Scale (EDSS)<sup>1</sup></strong
          >: This 10-point scale (from 1 to 10 with half points) measures
          degrees of disability, largely in terms of mobility. Points 1 to 3 on
          the scale are primarily used to measure function; whereas points 4 to
          9 measure mobility. Half points are used for higher clarity.
        </li>
        <li>
          <strong>GFAP<sup>6</sup></strong
          >: Glial fibrillary acidic protein (GFAP) is an intermediate filament
          protein that is primarily expressed by several cells in the CNS
          including astrocytes. Elevated levels are associated with astroglial
          injury and may be a potential biological marker of neurological
          disease activity.
        </li>
        <li>
          <strong>Immune cells (B cells, T cells)<sup>1</sup></strong
          >: B cells are immune cells that produce antibodies to fight against
          antigens within the body and help to regulate other immune cells. T
          cells are immune cells that have the ability to increase an immune
          response within the body, causing inflammation and damage in MS.
        </li>
        <li></li>
        <li>
          <strong>Immunoglobulins<sup>7</sup></strong
          >: Antibodies, also known as immunoglobulins (Igs), function as part
          of the healthy immune system to destroy bacteria and viruses. There
          are 5 classes: IgG is the most common (70%-80%) and forms the basis of
          long-term protection from microorganisms; IgA (15%) provides
          protection in mucosal body areas; IgM (10%) is the first line of
          response to infection. Present in small amounts, IgE is associated
          with allergic reactions, while the role of IgD is poorly understood.
        </li>
        <li>
          <strong>Lesions<sup>4</sup></strong
          >: Lesions (or plaque) refer to areas of damage or scarring
          (sclerosis) in the CNS caused by inflammation in MS. These lesions can
          be spotted on MRI, with active lesions appearing as white patches.
        </li>
        <li>
          <strong>Lhermitte’s sign<sup>1,4</sup></strong
          >: An electric shock–like sensation down the spine and legs when the
          neck is flexed forward. Individuals with MS may experience this type
          of pain. Although it usually does not require any treatment, it is
          often an indication of myelin damage in the spinal cord area.
        </li>
        <li>
          <strong>Lymphocytes<sup>1</sup></strong
          >: A type of white blood cell that plays a strong role in the body’s
          immune system by working to defend the body against foreign bodies and
          disease. In MS, the lymphocytes may be misdirected to cause damage to
          the CNS.
        </li>
        <li>
          <strong>Magnetic resonance imaging (MRI)<sup>1,4</sup></strong
          >: A scanning technique that uses a computer, radiofrequency
          stimulator, and a large electromagnet to provide a 3D representation
          of the brain and/or spine.
        </li>
        <li>
          <strong>McDonald criteria<sup>4</sup></strong
          >: A set of clinical criteria to aid in the diagnosis of MS,
          originally authored by an international panel of experts on the
          condition in 2010 and updated in 2017.
        </li>
        <li>
          <strong>Myelin<sup>1</sup></strong
          >: A fatty protein that serves as a protective covering and insulation
          to the nerves (axons), which work like wires to carry messages within
          the CNS.
        </li>
        <li>
          <strong>Neurodegeneration<sup>4</sup></strong
          >: Damage caused to the myelin sheath of cells in the CNS and believed
          to be a major contributor to neurological disability in MS.
        </li>
        <li></li>
        <li>
          <strong>Neurofilament light chain<sup>6</sup></strong
          >: Neurofilament (NfL) is a neuron-specific protein that is released
          into the extracellular cerebrospinal fluid following axonal damage.
          Light-chain NfL is a potential biological marker of disease activity
          in MS.
        </li>
        <li>
          <strong>Neuroinflammation<sup>2</sup></strong
          >: Inflammation is a natural part of the body’s defense to injury or
          infection. In MS, B cells can secrete proinflammatory cytokines,
          including tumor necrosis factor (TNF)-α, interleukin (IL)-6, and
          granulocyte-macrophage colony-stimulating factor (GM-CSF), which
          promote inflammation in the CNS.
        </li>
        <li>
          <strong>Neurologist<sup>4</sup></strong
          >: The “point person” for monitoring MS treatment and managing MS
          symptoms. This specialist typically focuses on conditions affecting
          the CNS.
        </li>
        <li>
          <strong>Neuropathology<sup>8</sup></strong
          >: Abnormalities that are associated with specific diseases of the
          CNS. In MS, the most specific neuropathological changes are focal
          lesions with primary demyelination, astrocytic scarring, and
          neuroinflammation.
        </li>
        <li>
          <strong>No evidence of disease activity (NEDA)<sup>4</sup></strong
          >: NEDA is term used when a person with MS has no relapses, disability
          progression, or new disease activity detected on MRI over a specific
          period of time.
        </li>
        <li>
          <strong>NEDA-3<sup>9</sup></strong
          >: No evidence of disease activity-3 is defined as the absence of
          relapses, disability progression, and MRI activity (new/enlarged T2
          lesions and/or gadolinium-enhanced T1 lesions).
        </li>
        <li>
          <strong>NEDA-4<sup>9</sup></strong
          >: No evidence of disease activity-4 status is defined as meeting all
          NEDA-3 criteria plus having an annualized BVL (brain volume loss) of
          ≤0.4%.
        </li>
        <li>
          <strong>Oligoclonal band (OCB)<sup>1</sup></strong
          >: Abnormal immune proteins called immunoglobulins that are present in
          the CSF; OCBs are not specific to MS.
        </li>
        <li>
          <strong>Optic neuritis<sup>1</sup></strong
          >: An inflammation of the optic nerve that can cause decreased or
          blurred vision.
        </li>
        <li>
          <strong>Paresthesia<sup>1</sup></strong
          >: A tingling, burning, or numbing sensation.
        </li>
        <li>
          <strong>Primary progressive MS (PPMS)<sup>1</sup></strong
          >: A subtype of MS characterized by a steady accumulation of
          neurological problems from the onset, without the presence of relapses
          and remissions.
        </li>
        <li>
          <strong>Relapsing-remitting MS (RRMS)<sup>1</sup></strong
          >: A subtype of MS characterized by temporary symptom flare-ups (also
          referred to as relapses, attacks, exacerbations, or bouts), which
          typically last for 1 to 3 months. These attacks are followed by
          periods of complete or partial recovery.
        </li>
        <li>
          <strong>Secondary progressive MS (SPMS)<sup>1</sup></strong
          >: A subtype of MS that progresses from RRMS. It is reached when the
          patient experiences a progressive worsening of symptoms.
        </li>
        <li>
          <strong>Sclerosis<sup>4</sup></strong
          >: A general hardening of the body tissue. The term “multiple
          sclerosis” refers to the multiple areas of scar tissue—often called
          “lesions”—that develop along affected nerve fibers and are visible on
          MRI.
        </li>
        <li>
          <strong>Spasticity<sup>1</sup></strong
          >: A tightness or stiffness of the muscles, occurring typically in the
          legs (calf or thigh), groin, buttocks, arms, or hands.
        </li>
      </ul>

      <h2>References</h2>
      <ol>
        <li>
          My MSAA. Glossary of multiple sclerosis terms. Accessed March 10,
          2022. https://mymsaa.org/ms-information/glossary/
        </li>
        <li>
          Baecher-Allan C, Kaskow BJ, Weiner HL. Multiple sclerosis: mechanisms
          and immunotherapy. <i>Neuron</i>. 2018;97:742-768.
          doi:10.1016/j.neuron.2018.01.021
        </li>
        <li>
          Li R, Patterson KR, Bar-Or A. Reassessing B cell contributions in
          multiple sclerosis. <i>Nat Immunol</i>. 2018;19:696-707.
          doi:10.1038/s41590-018-0135-x
        </li>
        <li>
          Everyday Health. Speaking multiple sclerosis: a glossary of common
          terms. Accessed March 10, 2022.
          https://www.everydayhealth.com/multiple-sclerosis/speaking-multiple-sclerosis-glossary-common-terms/
        </li>
        <li>
          Thompson AJ et al. Diagnosis of multiple
          sclerosis: 2017 revisions of the McDonald criteria.
          <i>Lancet Neurol</i>. 2018;17:162-173.
          doi:10.1016/S1474-4422(17)30470&#8209;2
        </li>
        <li>
          Paul A, Comabella M, Gandhi R. Biomarkers in multiple sclerosis.
          <i>Cold Spring Harb Perspect Med</i>. 2019;9:a029058.
          doi:10.1101/cshperspect.a029058
        </li>
        <li>
          Testing.com. Accessed January 31, 2025. https://www.testing.com/tests/immunoglobulins-iga-igg-igm/
        </li>
        <li>
          Lassmann H. Pathogenic mechanisms associated with different clinical
          courses of multiple sclerosis. <i>Front Immunol</i>. 2019;9:3116.
          doi:10.3389/fimmu.2018.03116
        </li>
        <li>
          Giovannoni G et al. “No evident disease
          activity”: the use of combined assessments in the management of
          patients with multiple sclerosis.
          <i>Mult Scler</i>. 2017;23(9):1179-1187. doi:10.1177/1352458517703193
        </li>
      </ol>
    </InnerContent>
  </div>
</template>

<script>
export default {
  name: "Glossary",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~@/scss/styles.scss";

#glossary {
  width: 100%;
  position: relative;

  .inner-content {
    margin: 0 auto;
    padding-bottom: 20px;
  }
}

.title {
  font-size: rem(46px);
  color: $blue;
  margin: rem(60px) 0 rem(20px) 0;

  @include breakpoint(xs) {
    font-size: rem(24px);
    line-height: rem(24px);
    margin: rem(20px) 0;
  }

  @include breakpoint(md) {
    font-size: rem(36px);
    line-height: rem(40px);
  }
}

ul {
  margin: 20px 0 80px;
  padding: 0;
  column-count: 2;
  column-gap: 80px;
  list-style-type: none;

  @include breakpoint(xs) {
    column-count: 1;
  }

  li {
    margin-bottom: 10px;
    padding-left: 0;
    font-size: rem(16px);
    break-inside: avoid-column;
  }
}

ol {
  margin: 0;
  padding: 20px 0 0 20px;
  li {
    margin-bottom: 20px;
    padding-left: 10px;
    font-size: rem(16px);
    break-inside: avoid-column;

    &::marker {
      font-weight: bold;
    }
  }
}

a {
  text-decoration: none;
}
</style>
